@font-face {
    font-family: 'Gentle';
    src: url('./assets/fonts/Gentle/Gentle/Gentle-Regular.otf');
}

@font-face {
    font-family: 'ITC Garamond Std';
    src: url('./assets/fonts/ITCGaramondStd/ITCGaramondStd-LtNarrow.otf');
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter/static/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Integral CF';
    src: url('./assets/fonts/CF/IntegralCF-Regular.ttf');
}

iframe {
    display: none !important;
}